














































































































import { Component, Vue } from 'vue-property-decorator';
import { getComponent,getView } from '@/utils/helpers';
import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
import schedule from '@/router/routes/schedule';
import PersonnelSchedulerGantt from './PersonnelSchedulerGantt.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    PersonnelSchedulerNavbar: () => getView('PersonnelSchedulerNavbar'),
	PersonnelScheduler: () => getView('PersonnelScheduler'),
	PersonnelSchedulerGantt: () => getView('PersonnelSchedulerGantt'),
	Calendar: () => getView('Calendar'),
	WellTestSchedule: () => getView('WellTestSchedule'),
	UserSettings: () => getComponent('common/UserSettings'),
	PersonnelScheduleAddScheduleModal: () => getComponent('common/PersonnelScheduleAddScheduleModal'),
	PersonnelScheduleAddAssetRowModal: () => getComponent('common/PersonnelScheduleAddAssetRowModal'),
  },
})
export default class PersonnelScheduler extends Vue {

	personnelShedulerNavbarRefreshKey = 0

  loadingTasqs = false

  addNewTablePopup = false

  addNewRowPopup = false

  workflowAutomationDialog = true

  viewSelected = 'Schedule'

  didSelectView(view) {
	  this.viewSelected = view
  }

  didModifySchedule() {
	  this.personnelShedulerNavbarRefreshKey += 1
  }

  get activeWorkspace() {
    return scheduleModule.activePersonnelWorkspace;
  }

//   get activeSchedule() {
//     return scheduleModule.activePersonnelSchedule;
//   }

  callChildReloadPage() {
	  (this.$refs.myChildRef as PersonnelSchedulerGantt).initializePage()
  }

  callChildPublishPage() {
	  (this.$refs.myChildRef as PersonnelSchedulerGantt).publishChangesClicked()
  }



  created() {
	  this.resetPage()
  }

  async resetPage() {
    this.loadingTasqs = true;
	workspaceModule.setActivePage(null)
	workspaceModule.resetAllData()
	await scheduleModule.getPersonnelWorkspaceSched({})


	// this.existingTableID = this.$route.params.table_id
	if (scheduleModule.recentlyPostedNewScheduleID && scheduleModule.recentlyPostedNewScheduleID != null) {
		this.$router.push({
		name: 'PersonnelScheduler',
		params: {
			// @ts-ignore
			id: this.$route.params.id,
			schedule_id: scheduleModule.recentlyPostedNewScheduleID
		},
		});		
		
        // @ts-ignore
        scheduleModule.setActiveSchedule({
            id: scheduleModule.recentlyPostedNewScheduleID || ''
        });
		scheduleModule.setRecentlyPostedNewScheduleID(false)
	}


	// this.existingTableID = this.$route.params.table_id
	else if (this.$route.params.schedule_id != null && this.$route.params.id != null) {
		this.$router.push({
		name: 'PersonnelScheduler',
		params: {
			// @ts-ignore
			id: this.$route.params.id,
			schedule_id: this.$route.params.schedule_id
		},
		});		
		
        // @ts-ignore
        scheduleModule.setActiveSchedule({
            id: this.$route.params.schedule_id || ''
        });
	}

    this.loadingTasqs = false;
  }






  async addNewSchedule(role) {
	scheduleModule.setIsSavingAddNewSchedule(true)
	await scheduleModule.postNewPersonnelSchedule({
		workspace_id: scheduleModule.activePersonnelWorkspace.ID,
		role: role
	})
	// scheduleModule.setRecentlyPostedNewScheduleID(null)
	scheduleModule.setIsSavingAddNewSchedule(false)

	this.closeAddNewTablePopup()
	this.resetPage()
  }



  closeAddNewTablePopup() {
	  this.addNewTablePopup = false
  }

  closeAddNewRowPopup() {
	  this.addNewRowPopup = false
  }

  addNewTable() {
	  this.addNewTablePopup = true
  }


  addNewRow() {
	  this.addNewRowPopup = true
  }


	addNewShift() {
		(this.$refs.myChildRef as PersonnelSchedulerGantt).showAddShiftPopup(null)
	}



}
